import React from "react";
import styled from "styled-components";

import { Header, Footer, ContentWrapper } from "common/components";

const Container = styled.div`
  background: #211d34;
  padding: 40px 0;
  color: ${(props) => props.theme.colors.text};
`;

const Desc = styled.div`
  margin-top: 10px;
  font-size: ${(props) => props.theme.text.s};
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.title.m};
`;

const SubTitle = styled.div`
  margin-top: 30px;
  font-size: ${(props) => props.theme.title.s};
`;

const Terms = () => {
  return (
    <>
      <Header refs={[]} />
      <Container>
        <ContentWrapper>
          <Title>Үйлчилгээний нөхцөл</Title>
          <Desc>
            Master Code LLC built the MemoWords app as a Free app. This SERVICE
            is provided by Master Code LLC at no cost and is intended for use as
            is. This page is used to inform visitors regarding our policies with
            the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service. If you choose to use our Service,
            then you agree to the collection and use of information in relation
            to this policy. The Personal Information that we collect is used for
            providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at MemoWords unless
            otherwise defined in this Privacy Policy.
          </Desc>
          <SubTitle>Information Collection and Use</SubTitle>
          <Desc>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to email. The information that we request
            will be retained by us and used as described in this privacy policy.
            The app does use third party services that may collect information
            used to identify you. Link to privacy policy of third party service
            providers used by the app
            <ul>
              <li>Google Play Services</li>
              <li>Firebase Analytics hover</li>
              <li>Facebook</li>
            </ul>
          </Desc>
          <SubTitle>Log Data</SubTitle>
          <Desc>
            We want to inform you that whenever you use our Service, in a case
            of an error in the app we collect data and information (through
            third party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol ( IP )
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </Desc>
          <SubTitle>Cookies</SubTitle>
          <Desc>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory. This Service does not use these cookies explicitly. However,
            the app may use third party code and libraries that use cookies to
            collect information and improve their services. You have the option
            to either accept or refuse these cookies and know when a cookie is
            being sent to your device. If you choose to refuse our cookies, you
            may not be able to use some portions of this Service.
          </Desc>
          <SubTitle>Service Providers</SubTitle>
          <Desc>
            We may employ third-party companies and individuals due to the
            following reasons:
            <ul>
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf; </li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </Desc>
        </ContentWrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Terms;
