import React, { useEffect, useState, forwardRef } from "react";
import styled from "styled-components";
import YouTube from "react-youtube";

import { ContentWrapper } from "common/components";

const Container = styled.div`
  background: radial-gradient(#2f1995, #1c1536);
  padding: 40px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.title.m};
  text-align: center;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.text.m};
  margin-top: 10px;
  text-align: center;
`;

const VideoImg = styled.div`
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    display: none;
  }
`;

const MobileButtonContainer = styled.a`
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    display: flex;
  }
`;

const Button = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.m};
  border: ${(props) => `1px solid  ${props.theme.colors.border}`};
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.text.l};
  display: inline-block;
  padding: ${(props) => `${props.theme.space.s} ${props.theme.space.l}`};
  background: ${(props) => props.theme.colors.button};
  cursor: pointer;
`;

const YoutubeContainer = styled.div`
  display: ${(props: { isVisible: boolean }) =>
    props.isVisible ? "flex" : "none"};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;

const Lesson = forwardRef((props: any, ref: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [video, setVideo] = useState<any>(undefined);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      if (video) {
        video?.pauseVideo();
      }
      document.body.style.removeProperty("overflow");
    }
  }, [isVisible, video]);

  useEffect(() => {
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);

  const toggleVideo = () => {
    setIsVisible((v) => !v);
  };

  const onReady = (event: any) => {
    setVideo(event.target);
  };

  return (
    <Container ref={ref}>
      <ContentWrapper>
        <Title>MemoWords гэж юу вэ?</Title>
        <Description>
          10000+ үгсийн сантай олон хэлний аппликейшнд тавиай морил
        </Description>
        <VideoImg>
          <img
            src="/img/video.png"
            style={{ maxHeight: "485px" }}
            alt="thumbnail"
          />
        </VideoImg>
      </ContentWrapper>
      <MobileButtonContainer
        href="https://youtu.be/3BmtGn9eS-s"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>Танилцуулга үзэх</Button>
      </MobileButtonContainer>
      <ButtonContainer>
        <Button onClick={toggleVideo}>Танилцуулга үзэх</Button>
      </ButtonContainer>
      <YoutubeContainer isVisible={isVisible} onClick={toggleVideo}>
        <YouTube videoId="3BmtGn9eS-s" onReady={onReady} />
      </YoutubeContainer>
    </Container>
  );
});

export default Lesson;
