import React, { useRef } from "react";

import { Header, Footer } from "common/components";
import QRCode from "./QRCode";
import Lesson from "./Lesson";
import Pros from "./Pros";
import About from "./About";

const Home = () => {
  const ref1 = useRef();
  const ref2 = useRef();

  return (
    <>
      <Header refs={[ref1, ref2]} />
      <QRCode />
      <Lesson ref={ref1} />
      <About />
      <Pros ref={ref2} />
      <Footer />
    </>
  );
};

export default Home;
