import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ContentWrapper } from "common/components";

const Container = styled.div`
  background: #180f41;
  padding: 40px 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    flex-direction: column;
  }
`;

const Logo = styled.div`
  height: 60px;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    display: none;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;
`;

const Section1 = styled.div`
  flex: 1;
`;

const LinkItem = styled(Link)`
  color: ${(props) => props.theme.colors.text};
  &:hover {
    text-decoration: underline;
  }
`;

const Contact = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text};
  text-align: right;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    margin-top: 20px;
    text-align: left;
  }
`;

const Copyright = styled.div`
  margin-top: 20px;
  text-align: center;
  color: #7e7a91;
  font-size: ${(props) => props.theme.text.s};
`;

const Footer = () => {
  return (
    <Container>
      <ContentWrapper>
        <Content>
          <Section1>
            <Title>Холбоосууд</Title>
            <LinkItem to="/terms">Үйлчилгээний нөхцөл</LinkItem>
          </Section1>
          <Logo>
            <img src="/img/logo.png" alt="logo" />
          </Logo>
          <Contact>
            <Title>Холбоо барих</Title>
            <div>Утас: +976-99105773</div>
            <div>И-мэйл: info@mastercode.mn</div>
          </Contact>
        </Content>
        <Copyright>
          Copyright © 2020 Master Code LLC - All rights reserved
        </Copyright>
      </ContentWrapper>
    </Container>
  );
};

export default Footer;
