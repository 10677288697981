import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Home from "pages/home";
import Terms from "pages/terms";

const Routes = () => (
  <BrowserRouter>
    <Route exact path="/" component={Home} />
    <Route exact path="/terms" component={Terms} />
  </BrowserRouter>
);

export default Routes;
