import React from "react";
import styled from "styled-components";

import { ContentWrapper } from "common/components";

const Container = styled.div`
  background: radial-gradient(
    rgba(255, 246, 177, 0.28),
    rgba(255, 223, 0, 0.28)
  );
`;

const Content = styled.div`
  display: flex;
  padding: 40px 0;
`;

const Section1 = styled.div`
  flex: 1;
  margin: 40px 20px;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    display: none;
  }
`;

const MonsterImg = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Section2 = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.title.m};
  color: ${(props) => props.theme.colors.main};
  font-family: Bold;
`;

const Desc = styled.div`
  margin-top: 30px;
  line-height: 1.5;
`;

const FlagContainer = styled.div`
  height: 30px;
  position: relative;
`;

const FlagImg = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    top: 10px;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    top: 20px;
  }
`;

const FlagLeft = styled.div`
  left: 0;
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 30px 20px;
  border-color: transparent transparent #201753 transparent;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    top: 10px;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    top: 20px;
  }
`;

const FlagRight = styled.div`
  right: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 0 20px;
  border-color: transparent transparent transparent #201753;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    top: 10px;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    top: 20px;
  }
`;

const About = () => {
  return (
    <Container>
      <ContentWrapper>
        <Content>
          <Section1>
            <MonsterImg>
              <img src="/img/memoMonster.png" alt="memo monster" />
            </MonsterImg>
          </Section1>
          <Section2>
            <Title>
              Бүхнийг сурж чаддаг Memo мангастай хамт үгээ цээжилж хэлээ
              сурцгаая! ;)
            </Title>
            <Desc>
              Хэл сурах үйл явц нь өөрөө адармаатай бөгөөд хүнээс асар их тэсвэр
              тэвчээр шаарддаг ба тухайн хүний хичээл зүтгэл нэн түрүүнд
              тавигддаг. Сонсох, унших, бичих дасгал хийхээс гадна дүрэм, үгсийн
              сангаа нэмэх зэргээр тасралтгүй үргэлжилнэ. Тэгвэл хэл сурах
              энэхүү цогц үйл ажиллагааны нэг хэсэг болох үгсийн сангаа нэмэх
              буюу шинэ үгсийг тогтооход бидэнд бэрхшээл учирдаг. Цаасан дээр
              шинэ үгсийн жагсаалт бичин олон дахин давтаж цээжлэх уламжлалт
              арга үр дүнгүй гэдэгтэй бүгд санал нийлэх биз ээ. Учир нь
              тогтоосон гэж бодсон хэдий ч шинэ үгээ хэдхэн хоногийн дараа уншиж
              буй бичвэрт гарч ирэхэд санахгүй, толь бичгээ дахин барьж авч
              байсан тохиолдол бидэнд олон бий.
            </Desc>
            <Desc>
              Тэгвэл Монголын Оюун Ухааны Академийн олон жил амжилттай хэрэгжсэн
              гадаад хэлний үг цээжлэх сургалтын хөтөлбөр дээр үндэслэн ой
              тогтоолтын арга буюу таны тархины ажиллах онцлогт тохирсон,
              сонирхолтой, хөгжилтэй хэлбэрээр хүссэн хэлний үгээ цээжлэх олон
              хэлний MemoWords аппликейшн таны гар дээр очоод байна.
            </Desc>
            <Desc>
              Хөгжүүлэлт хийгдэж үгсийн сан тогтмол нэмэгдэх энэ апп-ын
              тусламжтайгаар та Япон хэлний түвшин тогтоох шалгалт, Солонгос
              хэлний Тоpik, Англи хэлний TOEFL-ийн шалгалт зэрэг 10 хэлнээс
              сонгон суралцаж буй хэлний шалгалтыг амжилттай өгөх боломжтой юм.
            </Desc>
          </Section2>
        </Content>
        <FlagContainer>
          <FlagLeft />
          <FlagRight />
          <FlagImg>
            <img src="/img/flags.png" alt="flags" />
          </FlagImg>
        </FlagContainer>
      </ContentWrapper>
    </Container>
  );
};

export default About;
