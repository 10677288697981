import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 0 30px;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

interface Props {
  children: ReactNode;
}

const ContentWrapper = (props: Props) => {
  return <Container>{props.children}</Container>;
};

export default ContentWrapper;
