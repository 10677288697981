import React from "react";
import styled from "styled-components";

import { ContentWrapper } from "common/components";

const Container = styled.div`
  background-image: url("/img/background.png");
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
`;

const Section1 = styled.div`
  flex: 1;
  padding: 40px 20px;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    display: none;
  }
`;

const Section2 = styled.div`
  flex: 1;
  padding: 40px 20px;
`;

const MemoImg = styled.div`
  display: flex;
  justify-content: center;
`;

const Store = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  > * {
    margin: ${(props) => `0 ${props.theme.space.m}`};
  }
`;

const PhoneImg = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const QR = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const QRText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.text.s};
  font-family: Bold;
  margin-top: 40px;
`;

const QRCode = () => {
  return (
    <Container>
      <ContentWrapper>
        <Content>
          <Section1>
            <PhoneImg>
              <img src="/img/phone.png" alt="phone img" />
            </PhoneImg>
          </Section1>
          <Section2>
            <MemoImg>
              <img
                src="/img/memo.png"
                alt="memo monster"
                style={{ maxHeight: "150px" }}
              />
            </MemoImg>
            <Store>
              <a
                href="https://apps.apple.com/app/memowords/id1497174550"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/appStore.png"
                  alt="appstore"
                  style={{ maxHeight: "50px" }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=mn.mastercode.memowords"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/playStore.png"
                  alt="playstore"
                  style={{ maxHeight: "50px" }}
                />
              </a>
            </Store>
            <QR>
              <img
                src="/img/qr.png"
                style={{ maxHeight: "138px" }}
                alt="qr code"
              />
            </QR>
            <QRText>QR кодыг уншуулаад апп-аа татаж аваарай.</QRText>
          </Section2>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default QRCode;
