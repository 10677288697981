import React, { forwardRef } from "react";
import styled from "styled-components";

import { ContentWrapper } from "common/components";

const Container = styled.div`
  background: radial-gradient(#4d39b2, #201753);
  padding: 80px 0 40px 0;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.title.m};
  text-align: center;
`;

const DescContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.text.m};
  margin-top: 10px;
  text-align: center;
  max-width: 500px;
`;

const ProContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const SingleProContainer = styled.div`
  flex: 1 50%;
  padding: 10px;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    flex: 1 100%;
  }
`;

const SinglePro = styled.div`
  display: flex;
  height: 100%;
  border: ${(props) => `1px solid ${props.theme.colors.border}`};
  border-radius: ${(props) => props.theme.borderRadius.m};
  padding: 25px;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    flex-direction: column;
    padding: 5px;
  }
`;

const ProImg = styled.div`
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const ProInfo = styled.div`
  flex: 1;
  margin-left: 25px;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    text-align: center;
    margin: 0;
    padding: 5px;
  }
`;

const ProTitle = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.text.xl};
`;

const ProDesc = styled.div`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.text.m};
`;

const Pros = forwardRef((props: any, ref: any) => {
  return (
    <Container ref={ref}>
      <ContentWrapper>
        <Title>Давуу талууд</Title>
        <DescContainer>
          <Description>
            Та 10000+ үгийг хэзээ ч мартагдахааргүй үр дүнтэй, уйдаж
            залхахааргүй аргаар суралцах болно.
          </Description>
        </DescContainer>
        <ProContainer>
          <SingleProContainer>
            <SinglePro>
              <ProImg>
                <img src="/img/pro1.png" alt="pros" />
              </ProImg>
              <ProInfo>
                <ProTitle>10000+ Үгсийн сантай олон хэлний апп</ProTitle>
                <ProDesc>
                  Англи, Солонгос, Турк, Герман зэрэг олон хэлний үгс цээжлэх
                  дасгал
                </ProDesc>
              </ProInfo>
            </SinglePro>
          </SingleProContainer>
          <SingleProContainer>
            <SinglePro>
              <ProImg>
                <img src="/img/pro2.png" alt="pros" />
              </ProImg>
              <ProInfo>
                <ProTitle>Тархины ажиллах онцлогт тохирсон</ProTitle>
                <ProDesc>
                  Тархины ажиллагаанд тусгайлан зориулж бэлтгэгдсэн үг цээжлэх
                  аргачлал
                </ProDesc>
              </ProInfo>
            </SinglePro>
          </SingleProContainer>
          <SingleProContainer>
            <SinglePro>
              <ProImg>
                <img src="/img/pro3.png" alt="pros" />
              </ProImg>
              <ProInfo>
                <ProTitle>Үг цээжлэх хамгийн үр дүнтэй арга</ProTitle>
                <ProDesc>Таны цээжилсэн үгс хэзээ ч мартагдахгүй</ProDesc>
              </ProInfo>
            </SinglePro>
          </SingleProContainer>
          <SingleProContainer>
            <SinglePro>
              <ProImg>
                <img src="/img/pro4.png" alt="pros" />
              </ProImg>
              <ProInfo>
                <ProTitle>Хөгжилтэй, сонирхолтой Хялбархан</ProTitle>
                <ProDesc>Өрсөлдөөн, урамшуулалтай тэмцээнүүд</ProDesc>
              </ProInfo>
            </SinglePro>
          </SingleProContainer>
        </ProContainer>
      </ContentWrapper>
    </Container>
  );
});

export default Pros;
