import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

import ContentWrapper from "./ContentWrapper";

const Container = styled.div`
  height: 60px;
  background: ${(props) => props.theme.colors.main};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  height: 60px;
  padding: 10px 0;
`;

const Nav = styled.div`
  display: flex;
  > * {
    padding: ${(props) => props.theme.space.m};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.text.s};
  }
`;

const NavItem = styled.div`
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    display: none;
  }
`;

interface Props {
  refs: any;
}

const Header = (props: Props) => {
  const theme: any = useContext(ThemeContext);

  const scrollTo = (ref: any) => {
    window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });
  };

  return (
    <>
      <Container>
        <ContentWrapper>
          <Content>
            <Link to="/">
              <Logo>
                <img src="img/logo.png" alt="logo" />
              </Logo>
            </Link>
            <Nav>
              {props.refs[0] && (
                <NavItem onClick={() => scrollTo(props.refs[0])}>
                  Memowords гэж юу вэ?
                </NavItem>
              )}
              {props.refs[1] && (
                <NavItem onClick={() => scrollTo(props.refs[1])}>
                  Давуу талууд
                </NavItem>
              )}
              <a
                href="https://www.facebook.com/memowordsapp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} color={theme.colors.text} />
              </a>
            </Nav>
          </Content>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default Header;
