const pallete = {
  blueDark: "#33248D",

  black: "#000",
  white: "#fff",
};

export const themes = {
  light: {
    text: {
      s: "14px",
      m: "16px",
      l: "18px",
      xl: "20px",
    },
    title: {
      s: "25px",
      m: "30px",
    },
    space: {
      xs: "5px",
      s: "10px",
      m: "15px",
      l: "20px",
      xl: "25px",
    },
    colors: {
      main: pallete.blueDark,
      text: pallete.white,
      border: pallete.white,
      black: pallete.black,
      button: "#4B3A9B",
    },
    responsive: {
      tablet: "900px",
      mobile: "600px",
    },
    borderRadius: {
      m: "5px",
    },
  },
};
